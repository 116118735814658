<template>
  <StaticPage>
    <el-card class="form_wrapper">
      <h2>{{ $t('common.button.forgotPw') }}</h2>
      <div class="from_box" v-if="formFlag">
        <el-form :model="emailForm" ref="emailForm" @submit.native.prevent :rules="rule">
          <p>{{ $t('forgetPwReq.tip') }}</p>
          <InputTemplate v-model.trim="emailForm.email" name="email" :label="$t('common.field.email')"> </InputTemplate>
          <el-button type="primary" @click="submitForm('emailForm')">
            {{ $t('common.button.submit') }}
          </el-button>
        </el-form>
        <p class="error" v-if="errorFlag">{{ errMsg }}</p>
      </div>
      <div class="result" v-else>
        <img src="@/assets/images/forgetPasswordIcon.png" alt="" />
        <p v-html="$t('forgetPwReq.succ')"></p>
        <p>{{emailForm.email}}</p>
      </div>
    </el-card>
  </StaticPage>
</template>

<script>
import StaticPage from '@/components/template/staticPage/StaticPageDefault';
import { apiReq_reset_profile_password } from '@/resource';

export default {
  name: 'ForgotPassword',
  components: { StaticPage },
  data() {
    return {
      formFlag: true,
      emailForm: {
        email: ''
      },
      rule: {
        email: [
          {
            required: true,
            message: this.$t('forgetPwReq.formValidation.emailReq'),
            trigger: 'blur'
          }
        ]
      },
      errMsg: '',
      errorFlag: false
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) this.getApiReq_reset_profile_password();
        else return false;
      });
    },
    getApiReq_reset_profile_password() {
      this.errMsg = '';
      this.errorFlag = false;

      apiReq_reset_profile_password({
        email: this.emailForm.email,
        baseUrl: location.protocol + '//' + location.hostname
      }).then(resp => {
        if (resp.data.code === 0) {
          if (resp.data.data.result == true) {
            this.formFlag = false;
          }
        } else if (resp.data.code === 421) {
          this.errMsg = resp.data.errmsg;
          this.errorFlag = true;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
@import '@/assets/css/pages/forgetPassword/forgetPasswordReq.scss';
</style>
